.about-section {
  height: max-content;
  position: relative;

  @media (max-width: 700px) {
    min-height: 700px;
    gap: 16rem;
  }

  video {
    width: 80vw;
    height: auto;
    // height: 80vh;
  }

  img {
    width: 40vw;
    z-index: -1;
    @media (max-width: 700px) {
      width: 100%;
      height: 60%;
      position: absolute;
      top: 0;
      right: 0;
      object-fit: cover;
      opacity: 0.5;
    }
  }
}
