.gallery {
  margin-top: 100px;
  img {
    width: 35vw;
    cursor: pointer;
    transition: 500ms;
    -o-transition: 500ms;
    -webkit-transition: 500ms;
    -moz-transition: 500ms;

    border-radius: 5px;

    &:hover {
      transform: scale(0.95);
      box-shadow: 0px 0px 10px white;
    }

    &:nth-of-type(5n) {
      width: 50vw;
    }
  }
}

.focusedGallery {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.85);

  & > div {
    width: 60%;
    @media (max-width: 1000px) {
      width: 90%;
    }
  }

  .imageContainer {
    position: relative;
    img {
      // position: absolute;
      width: 100%;
    }
    & button {
      position: absolute;
      z-index: 5;
      height: 100%;
      width: 25%;
      font-size: 150px;

      cursor: pointer;

      transition: 500ms;
      -o-transition: 500ms;
      -webkit-transition: 500ms;
      -moz-transition: 500ms;

      background-color: black;
      color: white;
      opacity: 0.1;
      &:hover {
        opacity: 0.6;
      }
    }
    & button:nth-of-type(1) {
      left: 0;
    }
    & button:nth-of-type(2) {
      right: 0;
    }
  }

  .closingBar {
    width: 100%;
    height: 40px;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: end;

    border-radius: 15px 15px 0 0;
    button {
      background-color: transparent;

      border-radius: 100%;
      height: 40px;
      width: 40px;
      outline: none;
      border: none;
      font-size: 20px;
      font-weight: bold;

      cursor: pointer;

      display: flex;
      align-items: center;
      justify-content: center;

      transition: 500ms;
      -o-transition: 500ms;
      -webkit-transition: 500ms;
      -moz-transition: 500ms;

      &:focus {
        outline: none;
        border: none;
      }

      &:hover {
        background-color: white;
        transform: scale(0.8);
      }
    }
  }
}
