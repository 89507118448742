#header {
  outline: 1px solid red;
  height: 250px;

  background-color: grey;

  display: flex;
  align-items: center;
  justify-content: center;

  -webkit-clip-path: polygon(0 0, 100% 0, 85% 100%, 0% 100%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);

  video {
    position: absolute;
    width: 100%;
    transform: translateY(15%);
    // height: 250px;
    z-index: 2;
  }

  img {
    width: 300px;
    z-index: 3;
  }
}
