.homeButton {
  transition: 500ms;
  -o-transition: 500ms;
  -webkit-transition: 500ms;
  -moz-transition: 500ms;
  cursor: pointer;

  padding: 5px;

  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;

  border-radius: 30%;

  img {
    width: 50px;
  }

  &:hover {
    transform: scale(0.8);
  }
}
