.portfolio-container {
  width: 850px;
  gap: 0;
  padding-bottom: 100px;
  @media (max-width: 500px) {
    width: 100%;
  }

  a,
  div {
    margin: 0;
    padding: 0;
    overflow: hidden;
    cursor: pointer;
  }
  img {
    width: 400px;
    height: 400px;

    transition: 500ms;
    -o-transition: 500ms;
    -webkit-transition: 500ms;
    -moz-transition: 500ms;

    filter: grayscale(1);
    transform: scale(1.1);

    &:hover {
      transform: scale(1.2);
      filter: grayscale(0);
    }
    @media (max-width: 500px) {
      width: 280px;
      height: 280px;
    }
  }
}
